.contact-container {
  min-height: 100vh; /* 100% of the viewport height */
  width: 100%; /* 100% of the viewport width */
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Inter;
}

p {
  font-size: 16px;
}
