@tailwind base;
@tailwind components;
@tailwind utilities;

/* index.css */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "YoungSerif";
  src: local("YoungSerif"),
    url("./fonts/YoungSerif-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "InterBold";
  src: local("InterBold"), url("./fonts/Inter-Bold.ttf") format("truetype");
}
