.about-container {
  min-height: 100vh; /* 100% of the viewport height */
  width: 100%; /* 100% of the viewport width */
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Inter;
}

h1 {
  font-family: YoungSerif;
  font-size: 60px;
}

h2 {
  font-family: YoungSerif;
  font-size: 24px;
}

p {
  font-size: 18px;
}

.title-container {
  padding-bottom: 4px;
}

.icons-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 8.5vh;
  overflow: visible;
  align-items: flex-end;
}

.icon {
  width: 5vw;
  height: 10vh;
  min-width: 45px;
  min-height: 45px;
  max-width: 55px;
  min-height: 55px;
  margin-top: 0px;
  padding-top: 0px;
}

#title-caption {
  padding-top: 8.5vh;
}

.typing-effect {
  min-height: 6em; /* Reserve enough height for two lines of text */
  display: inline-block; /* Keep the space intact even when empty */
  line-height: 1.5em; /* Adjust line spacing to match font size */
  font-size: 1.25em;
}

.blinking-cursor {
  display: inline-block;
  width: 1ch; /* Width of a single character */
  height: 1.5em; /* Match the line height of the text */
  background-color: transparent;
  animation: blink 1s step-start infinite;
  color: rgb(135, 135, 135); /* Matches the text color */
}

@keyframes shine {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 200%;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

/* for viewing screens of <= 640 px*/
@media (max-width: 640px) {
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 18px;
  }
  p {
    font-size: 16px;
  }
}

@media (max-height: 640px) {
  .icons-container {
    height: 50px;
  }
  #title-caption {
    padding-top: 7vh;
  }
}
